import { GetStaticPropsContext, GetStaticPropsResult } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import Error4xx from '@/features/errors/pages/Error4xx'
import { DEFAULT_LOCALE } from '@/lib/localization/constants'

export async function getStaticProps(
  ctx: GetStaticPropsContext,
): Promise<GetStaticPropsResult<{}>> {
  const { locale = DEFAULT_LOCALE } = ctx

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}

export default Error4xx
