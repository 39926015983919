import { Fragment } from 'react'
import { NextSeo } from 'next-seo'
import { Box, Grid, Text, Button, Flex, Image } from '@chakra-ui/react'
import Link from 'next/link'

function Error400(): React.ReactElement {
  return (
    <Fragment>
      <NextSeo title="404 - Not found" />
      <Flex
        width="100%"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Box maxW="850px" m="0 auto" p="4">
          <Grid
            gridTemplateColumns={{ base: '1fr', md: 'max-content 1fr' }}
            gap="4"
            alignItems="center"
          >
            <Box mr="8" textAlign="center">
              <Image
                maxW={{ base: '100%', sm: '430px' }}
                src="/images/404-error-bro@2x.png"
              />
            </Box>
            <Grid gap="1">
              <Text as="h2" fontSize="36px" fontWeight="700">
                Oops!
              </Text>
              <Text fontSize="26px">This page you looking for</Text>
              <Text fontSize="26px">is not found.</Text>
              <Link href="/" passHref>
                <Button
                  variant="outline"
                  colorScheme="gray"
                  size="lg"
                  mt="4"
                  borderRadius="md"
                >
                  Back Homepage
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Flex>
    </Fragment>
  )
}

export default Error400
